<template>
  <div>
    <el-page-header
      class="u-bottom-margin--2x"
      :title="$t('GlobalsBackBtn')"
      :content="$t('ProductDetailsPageTitle', [productDetails.name])"
      @back="goBack"
    ></el-page-header>

    <product-details-card
      v-if="isLoaded"
      :isEditMode="true"
      :initialData="productDetails"
      @product-saved="saveForm"
    ></product-details-card>
    <!-- ---------------------------------------------------- -->
    <!-- Units Area -->
    <!-- ---------------------------------------------------- -->
    <unit-details-card
      v-if="unitsLoaded"
      :isEditMode="true"
      :isSaved="isUnitSaved"
      :initialData="unitsDetails"
      @new-unit-saved="assignNewUnit"
      @unit-deleted="deleteUnit"
      @unit-updated="unitUpdated"
    ></unit-details-card>
    <!-- ---------------------------------------------------- -->
    <!-- Providers Area -->
    <!-- ---------------------------------------------------- -->
    <div class="u-top-margin--5x">
      <styled-table
        :data="providerDetails"
        border
        style="width: 100%"
        :span-method="objectSpanMethod"
      >
        <el-table-column
          prop="name"
          :label="$t('AddProductPageDistributerNameLabel')"
        >
        </el-table-column>

        <el-table-column prop="unitDetails" :label="$t('unit')">
          <template slot-scope="scope">
            {{ scope.row.unitDetails.name }}
          </template>
        </el-table-column>
        <el-table-column
          prop="cashPrice"
          :label="$t('AddProductPageDistributerPrice')"
        >
        </el-table-column>

        <el-table-column
          prop="isInStock"
          :label="$t('AddProductPageDistributerNameIsInStockLabel')"
        >
          <template slot-scope="scope">
            {{ scope.row.isInStock ? "In Stock" : "Out of stock" }}</template
          >
        </el-table-column>
        <el-table-column prop="isOffer" label="Is Offer">
          <template slot-scope="scope">
            {{ scope.row.isOffer ? "Is Offer" : "Is not Offer" }}</template
          >
        </el-table-column>
      </styled-table>
    </div>
  </div>
</template>

<script>
import { productsService } from "../../services";
import { ref, onMounted, getCurrentInstance } from "vue";
import ProductDetailsCard from "./components/ProductDetailsCard.vue";
import UnitDetailsCard from "./components/UnitDetailsCard.vue";
import StyledTable from "../../components/dataContainers/styledTable.vue";
import { utils } from "../../utils";

export default {
  name: "productDetailsPage",
  components: { ProductDetailsCard, UnitDetailsCard, StyledTable },
  props: ["id"],

  setup(props) {
    const root = getCurrentInstance().proxy;
    // ***********************************************************
    // Product Details Area
    // ***********************************************************
    const productDetails = ref({});
    const productLoaded = ref(false);
    onMounted(async () => {
      const productRes = await productsService.getproduct(props.id);
      productDetails.value = productRes["data"];
      if (productDetails.isBasket) productDetails.value.isBasket = true;
      productLoaded.value = true;
    });
    const saveForm = async (payload) => {
      const { imageData, productObject } = payload;
      try {
        let productRes = await productsService.editProduct(
          props.id,
          productObject
        );

        if (imageData.imageFile) {
          let productFD = new FormData();
          productFD.append("image", imageData.imageFile);
          productFD.append("ids", JSON.stringify([productRes.id]));
          await productsService.bulkEditProductsImages(productFD);
        }
        productDetails.value = productRes["data"];

        root.$notify({
          title: root.$t("AddProductPageSuccessNotificationTitle"),
          message: root.$t("ProductDetailsPageNotificationBody"),
          type: "success",
        });
      } catch (err) {
        const errorMessage = utils.errorMessageExtractor(err);
        root.$notify({
          title: root.$t("AddProductPageErrorNotificationTitle"),
          message: errorMessage,
          type: "error",
        });
      }
    };
    // ***********************************************************
    // Providers logic
    // ***********************************************************
    const providerDetails = ref([]);
    const providerLoaded = ref(false);
    const getProductProviders = async () => {
      const ProviderRes = await productsService.getProvidersOfCertainProduct(
        props.id
      );
      const productProvidersList = [];
      ProviderRes.data.data.providers.forEach((providerUnit) => {
        providerUnit.entityProductDetails.forEach((productUnit) => {
          const mappedObj = {
            ...productUnit,
            name: providerUnit.entityName,
            id: providerUnit.entityId,
          };
          productProvidersList.push(mappedObj);
        });
      });
      providerDetails.value = productProvidersList;
      providerLoaded.value = true;
    };
    onMounted(() => {
      getProductProviders();
    });
    const objectSpanMethod = ({ row, columnIndex }) => {
      if (columnIndex === 0) {
        const currentProductItems = providerDetails.value.filter(
          (item) => item.id === row.id
        );
        if (currentProductItems && currentProductItems.length > 1) {
          const currentObjectIndex = currentProductItems.findIndex(
            (item) =>
              item.id === row.id && item.unitDetails.id == row.unitDetails.id
          );
          if (currentObjectIndex === 0) {
            return {
              rowspan: currentProductItems.length,
              colspan: 1,
            };
          } else {
            return {
              rowspan: 1,
              colspan: 0,
            };
          }
        }
      }
      return {
        rowspan: 1,
        colspan: 1,
      };
    };
    // ***********************************************************
    // Units logic
    // ***********************************************************
    const unitsDetails = ref({});
    const unitsLoaded = ref(false);
    const isUnitSaved = ref(false);

    const getProductUnits = async () => {
      const unitsRes = await productsService.getUnitsofCertainProduct(props.id);
      unitsDetails.value = unitsRes.data.data.filter((unit) => !unit.isDeleted);
      unitsLoaded.value = true;
    };

    onMounted(() => {
      getProductUnits();
    });
    const assignNewUnit = async (newUnit) => {
      const unitObj = newUnit;
      unitObj.displayOrder = unitsDetails.value.length;
      const productUnitsObject = {
        productId: props.id,
        productUnitsDetails: [unitObj],
      };
      try {
        await productsService.assignMultipleUnitsOnProduct(productUnitsObject);
        unitsDetails.value.push(unitObj);

        root.$notify({
          title: root.$t("AddProductPageSuccessNotificationTitle"),
          message: root.$t("Unit assigned successfully"),
          type: "success",
        });
      } catch (err) {
        const errorMessage = utils.errorMessageExtractor(err);
        root.$notify({
          title: root.$t("AddProductPageErrorNotificationTitle"),
          message: errorMessage,
          type: "error",
        });
      }
    };
    const unitUpdated = async (units) => {
      const mappedObj = units.map((unit) => {
        const unitKeys = Object.keys(unit);
        const mappedUnit = JSON.parse(JSON.stringify(unit));
        unitKeys.forEach((key) => {
          if (mappedUnit[key] === null || mappedUnit[key] === undefined) {
            delete mappedUnit[key];
          }
        });

        return {
          productId: props.id,
          unitId: unit.unitId,
          newData: { ...mappedUnit },
        };
      });
      try {
        await productsService.bulkUpdateProductUnits(mappedObj);
        root.$notify({
          title: root.$t("AddProductPageSuccessNotificationTitle"),
          message: root.$t("ProductDetailsPageUnitsUpdateSucessMsg"),
          type: "success",
        });
      } catch (err) {
        const errorMessage = utils.errorMessageExtractor(err);
        root.$notify({
          title: root.$t("AddProductPageErrorNotificationTitle"),
          message: errorMessage,
          type: "error",
        });
      }
    };
    const getProductUnitProviders = async (unitId) => {
      const res = await productsService.getProvidersAssignedToProductUnit({
        unitId: unitId,
        productId: props.id,
      });
      return res.data.data.entities.map((provider) => provider.name);
    };
    const deleteUnit = async (unit) => {
      const productUnitDetails = {
        productId: props.id,
        unitId: unit.unitId,
      };
      const providers = await getProductUnitProviders(unit.unitId);
      const popupMessage = providers.length
        ? `Are you sure you want to delete this unit which assigned to providers : ${providers}`
        : "Are you sure you want to delete this unit";
      const valid = await root.$confirm(popupMessage, "Warning", {
        type: "warning",
      });
      if (!valid) return;
      try {
        await productsService.deleteProductUnit(productUnitDetails);
        await getProductUnits();
        root.$notify({
          title: "Success",
          message: "Unit Delete successfully",
          type: "success",
        });
      } catch (err) {
        const errorMessage = utils.errorMessageExtractor(err);
        root.$notify({
          title: root.$t("AddProductPageErrorNotificationTitle"),
          message: errorMessage,
          type: "error",
        });
      }
    };

    return {
      productDetails,
      isLoaded: productLoaded,
      saveForm,
      providerDetails,
      unitsLoaded,
      unitsDetails,
      assignNewUnit,
      unitUpdated,
      objectSpanMethod,
      deleteUnit,
      isUnitSaved,
    };
  },

  methods: {
    // *******************************
    // product Area
    // ******************************

    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped></style>
